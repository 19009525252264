@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Outfit', sans-serif;
}

.link {
    @apply underline focus-within:outline-none hover:text-gray-800;
}

fieldset {
    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 2px;
    border-style: groove;
    border-color: rgb(192, 192, 192);
    border-image: initial;
    
    legend {
        background-color: #000;
        color: #fff;
        padding: 2px 8px;
    }
}

// scrollbar

.scrollbar::-webkit-scrollbar-track
{
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: transparent;
}

.scrollbar::-webkit-scrollbar
{
	width: 10px;
	background-color: transparent;
}

.scrollbar::-webkit-scrollbar-thumb
{
	background-color: #000000;
	// border: 2px solid #555555;
}


// WP Styles

.components-base-control__field {
    @apply grid grid-cols-12;

    .components-base-control__label {
        @apply col-span-2;
    }

    .components-text-control__input {
        @apply col-span-10;
    }

}